var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-card', {
    staticClass: "mb-50 mb-md-1 border-info-2",
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('b-row', {
    staticClass: "mx-0 mb-50",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "bg-blue-gradient d-flex mb-25 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-alert', {
    staticClass: "p-75 p-md-1 mr-25 mr-md-75 mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "UsersIcon",
      "size": _vm.isMobileView ? '20' : '30'
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('div', {
    class: "text-airline fw-700 ".concat(_vm.isMobileView ? 'font-medium-4' : 'font-medium-3')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfo')) + " "), _vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-info cursor-pointer",
    attrs: {
      "id": "header-tooltip-mobile-pax",
      "icon": "InfoIcon",
      "size": "16"
    }
  }) : _vm._e()], 1), _vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "target": "header-tooltip-mobile-pax",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfoNote')))])]) : _vm._e(), !_vm.isMobileView ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfoNote')) + " ")]) : _vm._e()], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-md-end w-100",
    attrs: {
      "sm": "12",
      "md": "5"
    }
  }, [_vm.enableQuickName && _vm.isDevelopment && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2",
    attrs: {
      "id": "id-icon-quick-input-multiple-pax",
      "variant": "danger"
    },
    on: {
      "click": _vm.fakePaxInfo
    }
  }, [_c('feather-icon', {
    staticClass: "text-white mr-50 d-none d-md-inline",
    attrs: {
      "icon": "ZapIcon",
      "size": "14"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Nhập tên siêu nhanh")])], 1) : _vm._e(), _vm.suggestVisible && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2 pulse-button",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click() {
        return _vm.$bvModal.show('suggest-shop-modal');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50 d-none d-md-inline",
    attrs: {
      "icon": "SearchIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('suggestShopVJ')) + " ")])], 1)]) : _vm._e(), _vm.enableQuickName && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2",
    attrs: {
      "id": "id-icon-quick-input-multiple-pax",
      "variant": "warning"
    },
    on: {
      "click": _vm.handleShowModalQuickInput
    }
  }, [_c('feather-icon', {
    staticClass: "text-white mr-50 d-none d-md-inline",
    attrs: {
      "icon": "ZapIcon",
      "size": "14"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.isMobileView ? 'Nhập nhanh' : _vm.$t('flight.quickInputPax.title')))])], 1) : _vm._e(), !_vm.isEmpty(_vm.selectedTrip) && !_vm.selectedTrip.every(function (trip) {
    return _vm.SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source);
  }) && !_vm.isHideFunction ? _c('div', {
    staticClass: "mr-50"
  }, [_c('BookingAddMorePassenger', {
    attrs: {
      "pax-lists": _vm.passengerDataToAdd
    },
    on: {
      "changeQuantityPax": function changeQuantityPax(val) {
        return _vm.$emit('multipleQuantityPaxChange', val);
      }
    }
  })], 1) : _vm._e()], 1)], 1), _vm.selectedTrip.some(function (trip) {
    return ['VJ'].includes(trip.source);
  }) ? _c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-alert', {
    staticClass: "p-50 my-50",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" Lưu ý: \"Theo yêu cầu của VietjetAir tất cả hành khách phải nhập ngày sinh. Trong trường hợp Quý AG để trống chúng tôi sẽ cung cấp dữ liệu ngẫu nhiên. " + _vm._s(_vm.mamaShortName) + " không chịu trách nhiệm nếu quý AG không nhập đầy đủ thông tin !\" ")])])], 1) : _vm._e(), _vm._l(_vm.passengerDataToAdd, function (passenger, index) {
    return _c('b-card', {
      key: index,
      staticClass: "mb-50 mb-md-1",
      attrs: {
        "bg-variant": "light-secondary",
        "header-class": "px-25 py-0",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('b-row', {
            staticClass: "w-100",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            staticClass: "w-100 d-flex-between text-nowrap px-75",
            attrs: {
              "cols": "12"
            }
          }, [_c('div', [_c('span', {
            class: "text-body ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " fw-700")
          }, [_vm._v(" " + _vm._s(_vm.resolveHeaderNamePassenger(passenger, index)) + " ")])]), _c('div', {
            staticClass: "d-flex align-items-end align-items-lg-center justify-content-center"
          }, [_c('div', {
            staticClass: "d-flex justify-content-around m-end"
          }, [!_vm.isEmpty(_vm.selectedTrip) && !_vm.selectedTrip.every(function (trip) {
            return _vm.SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source);
          }) && !_vm.isHideFunction ? _c('b-button', {
            staticClass: "px-50 py-25",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeletePax(passenger);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "fw-600",
            attrs: {
              "icon": "XIcon",
              "size": "20"
            }
          })], 1) : _vm._e()], 1)])]), _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "2",
              "lg": "1"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Honorific",
              "rules": "required",
              "vid": "Honorific-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  }
                }, [_c('v-select', {
                  class: "select-size-sm honorific_select ".concat(_vm.isMobileView ? 'font-small-2' : 'font-medium-1', " font-weight-bolder"),
                  attrs: {
                    "id": "Honorific-".concat(passenger.paxType, "-").concat(index),
                    "options": _vm.titleOptions[passenger.paxType],
                    "label": "text",
                    "clearable": false,
                    "searchable": false,
                    "filterable": false,
                    "placeholder": "".concat(_vm.$t('flight.Honorific'), " (*)"),
                    "reduce": function reduce(val) {
                      return val.value;
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "option",
                    fn: function fn(data) {
                      return [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }, {
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }], null, true),
                  model: {
                    value: passenger.title,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "title", $$v);
                    },
                    expression: "passenger.title"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "Honorific-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _vm.isCombineFullName ? _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "10",
              "lg": "8"
            }
          }, [_c('HStack', {
            staticClass: "w-100"
          }, [_c('validation-provider', {
            staticClass: "flex-1",
            attrs: {
              "rules": "required|alphaSpaces|minWord:2,".concat(_vm.selectedTrip.some(function (trip) {
                return ['1A'].includes(trip.source);
              }) ? '2' : '1'),
              "name": _vm.$t('flight.fullName'),
              "vid": "fullName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  attrs: {
                    "id": "fullName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.fullName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body px-25 font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " ").concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'fullName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.fullName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.fullName);
                    },
                    "blur": function blur($event) {
                      return _vm.splitFullNameHandle(passenger);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref) {
                      var _suggestion$item, _suggestion$item2, _suggestion$item3, _suggestion$item4, _suggestion$item4$own, _suggestion$item5, _suggestion$item5$own, _suggestion$item6, _suggestion$item6$own, _suggestion$item7, _suggestion$item7$own, _suggestion$item8, _suggestion$item8$own;
                      var suggestion = _ref.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        attrs: {
                          "cols": (_suggestion$item = suggestion.item) !== null && _suggestion$item !== void 0 && _suggestion$item.owner ? 6 : 12
                        }
                      }, [_c('div', {
                        staticClass: "text-nowrap font-italic small"
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('div', {
                        staticClass: "text-uppercase"
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.fullName))
                        }
                      })]), _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? _vm.convertISODateTime(suggestion.item.birthDay).date : '') + " ")]), (_suggestion$item2 = suggestion.item) !== null && _suggestion$item2 !== void 0 && _suggestion$item2.identifyNumber ? _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" CCCD/PP: "), _c('span', {
                        staticClass: "fw-600"
                      }, [_vm._v(_vm._s(suggestion.item.identifyNumber))])]) : _vm._e()]), (_suggestion$item3 = suggestion.item) !== null && _suggestion$item3 !== void 0 && _suggestion$item3.owner ? _c('b-col', {
                        staticClass: "text-14px",
                        attrs: {
                          "cols": "6"
                        }
                      }, [_c('small', {
                        staticClass: "font-italic"
                      }, [_vm._v(_vm._s(_vm.$t('customer.Customer')) + ":")]), _c('div', {
                        staticClass: "d-flex gap-1"
                      }, [_c('b', [_vm._v(_vm._s((_suggestion$item4 = suggestion.item) === null || _suggestion$item4 === void 0 ? void 0 : (_suggestion$item4$own = _suggestion$item4.owner) === null || _suggestion$item4$own === void 0 ? void 0 : _suggestion$item4$own.lastName) + " " + _vm._s((_suggestion$item5 = suggestion.item) === null || _suggestion$item5 === void 0 ? void 0 : (_suggestion$item5$own = _suggestion$item5.owner) === null || _suggestion$item5$own === void 0 ? void 0 : _suggestion$item5$own.firstName))]), _vm._v(" (" + _vm._s((_suggestion$item6 = suggestion.item) === null || _suggestion$item6 === void 0 ? void 0 : (_suggestion$item6$own = _suggestion$item6.owner) === null || _suggestion$item6$own === void 0 ? void 0 : _suggestion$item6$own.code) + ") ")]), _c('div', [_vm._v(_vm._s((_suggestion$item7 = suggestion.item) === null || _suggestion$item7 === void 0 ? void 0 : (_suggestion$item7$own = _suggestion$item7.owner) === null || _suggestion$item7$own === void 0 ? void 0 : _suggestion$item7$own.emailAddress))]), _c('div', [_vm._v(_vm._s((_suggestion$item8 = suggestion.item) === null || _suggestion$item8 === void 0 ? void 0 : (_suggestion$item8$own = _suggestion$item8.owner) === null || _suggestion$item8$own === void 0 ? void 0 : _suggestion$item8$own.phoneNumber))])]) : _vm._e()], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.fullName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "fullName", $$v);
                    },
                    expression: "passenger.fullName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "fullName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          }), _c('IAmIcon', {
            staticClass: "cursor-pointer",
            attrs: {
              "id": "help-".concat(passenger.paxType, "-").concat(index),
              "icon": "infoCircleOutline",
              "size": "16",
              "color": "#EF5350"
            }
          }), _c('b-tooltip', {
            attrs: {
              "target": "help-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "custom-class": "tooltip-info-class",
              "boundary": "viewport",
              "variant": "light"
            }
          }, [_c('div', {
            domProps: {
              "innerHTML": _vm._s(_vm.$t('flight.combineFullNameHelp'))
            }
          })])], 1)], 1) : _vm._e(), !_vm.isCombineFullName ? _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "4",
              "lg": !_vm.isDobInputOutsideDetailBox ? 5 : 3
            }
          }, [_c('validation-provider', {
            attrs: {
              "rules": "required|alphaSpaces|min:".concat(_vm.selectedTrip.some(function (trip) {
                return ['1A'].includes(trip.source);
              }) ? '2' : '1'),
              "name": "Last Name",
              "vid": "lastName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  class: _vm.isMobileView ? 'custom-last-name-autosuggest' : 'custom-last-name-autosuggest-desktop',
                  attrs: {
                    "id": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.lastName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body px-25 font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " ").concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'lastName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.lastName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.lastName);
                    },
                    "blur": function blur() {
                      passenger.lastName = passenger.lastName.trim();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var _suggestion$item9, _suggestion$item10, _suggestion$item11, _suggestion$item12, _suggestion$item12$ow, _suggestion$item13, _suggestion$item13$ow, _suggestion$item14, _suggestion$item14$ow, _suggestion$item15, _suggestion$item15$ow, _suggestion$item16, _suggestion$item16$ow;
                      var suggestion = _ref2.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        attrs: {
                          "cols": (_suggestion$item9 = suggestion.item) !== null && _suggestion$item9 !== void 0 && _suggestion$item9.owner ? 6 : 12
                        }
                      }, [_c('div', {
                        staticClass: "text-nowrap font-italic small"
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('div', {
                        staticClass: "text-uppercase"
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.lastName))
                        }
                      })]), _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? _vm.convertISODateTime(suggestion.item.birthDay).date : '') + " ")]), (_suggestion$item10 = suggestion.item) !== null && _suggestion$item10 !== void 0 && _suggestion$item10.identifyNumber ? _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" CCCD/PP: "), _c('span', {
                        staticClass: "fw-600"
                      }, [_vm._v(_vm._s(suggestion.item.identifyNumber))])]) : _vm._e()]), (_suggestion$item11 = suggestion.item) !== null && _suggestion$item11 !== void 0 && _suggestion$item11.owner ? _c('b-col', {
                        staticClass: "text-14px",
                        attrs: {
                          "cols": "6"
                        }
                      }, [_c('small', {
                        staticClass: "font-italic"
                      }, [_vm._v(_vm._s(_vm.$t('customer.Customer')) + ":")]), _c('div', {
                        class: "d-flex gap-1 ".concat(_vm.isMobileView ? 'flex-column' : '')
                      }, [_c('b', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(_vm._s((_suggestion$item12 = suggestion.item) === null || _suggestion$item12 === void 0 ? void 0 : (_suggestion$item12$ow = _suggestion$item12.owner) === null || _suggestion$item12$ow === void 0 ? void 0 : _suggestion$item12$ow.lastName) + " " + _vm._s((_suggestion$item13 = suggestion.item) === null || _suggestion$item13 === void 0 ? void 0 : (_suggestion$item13$ow = _suggestion$item13.owner) === null || _suggestion$item13$ow === void 0 ? void 0 : _suggestion$item13$ow.firstName))]), _c('small', [_vm._v("(" + _vm._s((_suggestion$item14 = suggestion.item) === null || _suggestion$item14 === void 0 ? void 0 : (_suggestion$item14$ow = _suggestion$item14.owner) === null || _suggestion$item14$ow === void 0 ? void 0 : _suggestion$item14$ow.code) + ")")])]), _c('div', [_vm._v(_vm._s((_suggestion$item15 = suggestion.item) === null || _suggestion$item15 === void 0 ? void 0 : (_suggestion$item15$ow = _suggestion$item15.owner) === null || _suggestion$item15$ow === void 0 ? void 0 : _suggestion$item15$ow.emailAddress))]), _c('div', [_vm._v(_vm._s((_suggestion$item16 = suggestion.item) === null || _suggestion$item16 === void 0 ? void 0 : (_suggestion$item16$ow = _suggestion$item16.owner) === null || _suggestion$item16$ow === void 0 ? void 0 : _suggestion$item16$ow.phoneNumber))])]) : _vm._e()], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.lastName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "lastName", $$v);
                    },
                    expression: "passenger.lastName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1) : _vm._e(), !_vm.isCombineFullName ? _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "6",
              "lg": !_vm.isDobInputOutsideDetailBox ? 6 : 5
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "First Name",
              "rules": "required|alphaSpaces|min:".concat(_vm.selectedTrip.some(function (trip) {
                return ['1A'].includes(trip.source);
              }) ? '2' : '1'),
              "vid": "firstName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  class: {
                    'custom-first-name-autosuggest': _vm.isMobileView
                  },
                  attrs: {
                    "id": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.firstName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body px-25 ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " font-weight-bolder ").concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'firstName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.firstName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.firstName);
                    },
                    "blur": function blur() {
                      passenger.firstName = passenger.firstName.trim();
                    },
                    "select": function select() {
                      passenger.firstName = passenger.firstName.trim();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref3) {
                      var _suggestion$item17, _suggestion$item18, _suggestion$item19, _suggestion$item20, _suggestion$item20$ow, _suggestion$item21, _suggestion$item21$ow, _suggestion$item22, _suggestion$item22$ow, _suggestion$item23, _suggestion$item23$ow, _suggestion$item24, _suggestion$item24$ow;
                      var suggestion = _ref3.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        attrs: {
                          "cols": (_suggestion$item17 = suggestion.item) !== null && _suggestion$item17 !== void 0 && _suggestion$item17.owner ? 6 : 12
                        }
                      }, [_c('div', {
                        staticClass: "text-nowrap font-italic small"
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('div', {
                        staticClass: "text-uppercase"
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.firstName))
                        }
                      })]), _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? _vm.convertISODateTime(suggestion.item.birthDay).date : '') + " ")]), (_suggestion$item18 = suggestion.item) !== null && _suggestion$item18 !== void 0 && _suggestion$item18.identifyNumber ? _c('div', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" CCCD/PP: "), _c('span', {
                        staticClass: "fw-600"
                      }, [_vm._v(_vm._s(suggestion.item.identifyNumber))])]) : _vm._e()]), (_suggestion$item19 = suggestion.item) !== null && _suggestion$item19 !== void 0 && _suggestion$item19.owner ? _c('b-col', {
                        staticClass: "text-14px",
                        attrs: {
                          "cols": "6"
                        }
                      }, [_c('small', {
                        staticClass: "font-italic"
                      }, [_vm._v(_vm._s(_vm.$t('customer.Customer')) + ":")]), _c('div', {
                        class: "d-flex gap-1 ".concat(_vm.isMobileView ? 'flex-column' : '')
                      }, [_c('b', [_vm._v(_vm._s((_suggestion$item20 = suggestion.item) === null || _suggestion$item20 === void 0 ? void 0 : (_suggestion$item20$ow = _suggestion$item20.owner) === null || _suggestion$item20$ow === void 0 ? void 0 : _suggestion$item20$ow.lastName) + " " + _vm._s((_suggestion$item21 = suggestion.item) === null || _suggestion$item21 === void 0 ? void 0 : (_suggestion$item21$ow = _suggestion$item21.owner) === null || _suggestion$item21$ow === void 0 ? void 0 : _suggestion$item21$ow.firstName))]), _vm._v(" (" + _vm._s((_suggestion$item22 = suggestion.item) === null || _suggestion$item22 === void 0 ? void 0 : (_suggestion$item22$ow = _suggestion$item22.owner) === null || _suggestion$item22$ow === void 0 ? void 0 : _suggestion$item22$ow.code) + ") ")]), _c('div', [_vm._v(_vm._s((_suggestion$item23 = suggestion.item) === null || _suggestion$item23 === void 0 ? void 0 : (_suggestion$item23$ow = _suggestion$item23.owner) === null || _suggestion$item23$ow === void 0 ? void 0 : _suggestion$item23$ow.emailAddress))]), _c('div', [_vm._v(_vm._s((_suggestion$item24 = suggestion.item) === null || _suggestion$item24 === void 0 ? void 0 : (_suggestion$item24$ow = _suggestion$item24.owner) === null || _suggestion$item24$ow === void 0 ? void 0 : _suggestion$item24$ow.phoneNumber))])]) : _vm._e()], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.firstName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "firstName", $$v);
                    },
                    expression: "passenger.firstName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1) : _vm._e(), _vm.isDobInputOutsideDetailBox || _vm.isCombineFullName ? _c('b-col', {
            staticClass: "px-0 px-md-50 mt-25 mt-lg-0",
            attrs: {
              "cols": "12",
              "lg": "3"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('flight.dob'),
              "rules": passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title) ? _vm.getValidateBirthday('CHILD', passenger) : _vm.getValidateBirthday(passenger.paxType, passenger),
              "vid": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "id": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId)
                  }
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  model: {
                    value: passenger.birthday,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "birthday", $$v);
                    },
                    expression: "passenger.birthday"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control px-50 py-0",
                  style: {
                    height: '30px'
                  },
                  attrs: {
                    "id": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId),
                    "name": _vm.$t('flight.dob'),
                    "config": Object.assign({}, _vm.configFlatPickr, {
                      altInputClass: 'form-control input font_birthday_passenger  font-weight-bolder px-50 py-0 border-transparent'
                    }),
                    "placeholder": "".concat(_vm.$t('flight.dob'), " ").concat(_vm.getValidateBirthday(passenger.paxType, passenger).includes('required') ? '(*)' : '')
                  },
                  model: {
                    value: passenger.birthday,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "birthday", $$v);
                    },
                    expression: "passenger.birthday"
                  }
                })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), _c('b-col', {
            class: "d-flex-center ".concat(_vm.isDobInputOutsideDetailBox ? 'justify-content-start' : 'justify-content-end'),
            attrs: {
              "cols": "12"
            }
          }, [_c('div', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-".concat(passenger.paxType, "-").concat(index),
              expression: "`collapse-${passenger.paxType}-${index}`"
            }],
            staticClass: "d-flex-center px-50 py-25 gap-2 hover-underline text-info btn btn-flat-info my-25 mx-50 rounded",
            class: _vm.isDobInputOutsideDetailBox && !_vm.isMobileView ? 'font-medium-2' : 'font-small-3'
          }, [_c('feather-icon', {
            attrs: {
              "icon": "ChevronDownIcon",
              "size": "16"
            }
          }), _c('span', [_vm._v(" " + _vm._s(_vm.isDobInputOutsideDetailBox ? _vm.$t('flight[\'Additional information\']') : _vm.$t('flight.Details')) + " ")])], 1)])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-collapse', {
      attrs: {
        "id": "collapse-".concat(passenger.paxType, "-").concat(index),
        "visible": ['INFANT'].includes(passenger.paxType) || _vm.getValidateBirthday(passenger.paxType, passenger).includes('required') || _vm.selectedTrip.some(function (item) {
          return ['VJ'].includes(item === null || item === void 0 ? void 0 : item.source);
        }) && passenger.paxType === 'ADULT' || _vm.isEnableFormPassport() && _vm.getValidatePassport(passenger, 'number').includes('required') || _vm.selectedTrip.some(function (item) {
          return ['TR'].includes(item === null || item === void 0 ? void 0 : item.source);
        })
      }
    }, [_c('div', {
      staticClass: "pb-50"
    }, [_c('b-row', {
      staticClass: "mx-0"
    }, [!_vm.isDobInputOutsideDetailBox && !_vm.isCombineFullName ? _c('b-col', {
      staticClass: "my-25",
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('flight.dob'),
        "rules": passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title) ? _vm.getValidateBirthday('CHILD', passenger) : _vm.getValidateBirthday(passenger.paxType, passenger),
        "vid": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: passenger.birthday,
              callback: function callback($$v) {
                _vm.$set(passenger, "birthday", $$v);
              },
              expression: "passenger.birthday"
            }
          }), _c('div', {
            staticClass: "form-control p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId),
              "name": _vm.$t('flight.dob'),
              "config": Object.assign({}, _vm.configFlatPickr, {
                altInputClass: 'form-control input font_birthday_passenger  font-weight-bolder px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.dob'), " ").concat(_vm.getValidateBirthday(passenger.paxType, passenger).includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.birthday,
              callback: function callback($$v) {
                _vm.$set(passenger, "birthday", $$v);
              },
              expression: "passenger.birthday"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1) : _vm._e(), passenger.paxType === 'INFANT' ? _c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Người lớn đi cùng",
        "rules": passenger.paxType === 'INFANT' ? "required|duplicateParentId:".concat(_vm.parentSelected.toString()) : '',
        "vid": "parent-id-select-".concat(passenger.paxId)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "size": "sm",
              "state": _vm.getValidationState(validationContext) === false ? false : null
            }
          }, [passenger.paxType === 'INFANT' ? _c('v-select', {
            staticClass: "honorific_select",
            attrs: {
              "id": "parent-id-select-".concat(passenger.paxId),
              "options": _vm.parentOptions,
              "reduce": function reduce(val) {
                return val.paxId;
              },
              "clearable": false,
              "placeholder": "".concat(_vm.$t('flight.selectAdult'), " (*)"),
              "label": "firstName",
              "disabled": _vm.isDisabledSelectParentPaxId
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : "".concat(data.paxType, " #").concat(data.paxId)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : "".concat(data.paxType, " #").concat(data.paxId)) + " ")])];
              }
            }], null, true),
            model: {
              value: passenger.parentPaxId,
              callback: function callback($$v) {
                _vm.$set(passenger, "parentPaxId", $$v);
              },
              expression: "passenger.parentPaxId"
            }
          }) : _vm._e(), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "parent-id-select-".concat(passenger.paxId),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e(), _c('div', [_c('i', {
            class: "text-danger font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
          }, [_vm._v(" * " + _vm._s(_vm.$t('flight.requiredAdult')) + " ")])])], 1)];
        }
      }], null, true)
    })], 1) : _vm._e()], 1), _vm.isEnableFormPassport() ? _c('b-row', {
      staticClass: "mx-0 pb-50 px-25 px-md-1 passport_class",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3",
        "xl": "2"
      }
    }, [_c('b-form-select', {
      staticClass: "fw-700",
      attrs: {
        "id": "passenger.docTypeSelect"
      },
      model: {
        value: passenger.docTypeSelect,
        callback: function callback($$v) {
          _vm.$set(passenger, "docTypeSelect", $$v);
        },
        expression: "passenger.docTypeSelect"
      }
    }, [_vm.selectedTrip.some(function (trip) {
      return ['VJ'].includes(trip === null || trip === void 0 ? void 0 : trip.source) && _vm.isDomesticTrips;
    }) ? _c('b-form-select-option', {
      attrs: {
        "value": "C"
      }
    }, [_vm._v(" Căn cước công dân ")]) : _vm._e(), _c('b-form-select-option', {
      attrs: {
        "value": "P"
      }
    }, [_vm._v(" Hộ chiếu ")])], 1)], 1), _c('b-col', {
      staticClass: "d-flex gap-1",
      attrs: {
        "sm": "6",
        "md": ['C'].includes(passenger.docTypeSelect) ? '5' : '4',
        "lg": ['C'].includes(passenger.docTypeSelect) ? '4' : '3',
        "xl": "4"
      }
    }, [_c('validation-provider', {
      staticClass: "flex-1",
      attrs: {
        "rules": "".concat(_vm.getValidatePassport(passenger, 'number'), "|alphaNum"),
        "name": ['C'].includes(passenger.docTypeSelect) ? _vm.$t('flight.CCCD') : _vm.$t('flight.Passport'),
        "vid": "passport-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "passport-".concat(passenger.paxType, "-").concat(index),
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "lazy-formatter": "",
              "formatter": _vm.trimAllInputAndUpper,
              "placeholder": "".concat(['C'].includes(passenger.docTypeSelect) ? _vm.$t('flight.CCCD') : _vm.$t('flight.Passport'), " ").concat(_vm.getValidatePassport(passenger, 'number').includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].number,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "number", $$v);
              },
              expression: "passenger.PaxDocuments[0].number"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    }), _c('BButton', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.focus.top",
        value: "Qu\xE9t QR Code CCCD/Passport",
        expression: "`Quét QR Code CCCD/Passport`",
        modifiers: {
          "hover": true,
          "focus": true,
          "top": true
        }
      }],
      staticClass: "rounded-circle p-50",
      attrs: {
        "variant": "flat-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickQrScanHandle("qr-code-scan-passport-".concat(passenger.paxType, "-").concat(index));
        }
      }
    }, [_c('IAmIcon', {
      attrs: {
        "icon": "qrCode",
        "size": "18",
        "color": "black"
      }
    })], 1), _c('IAmQrCodeScan', {
      attrs: {
        "idProp": "qr-code-scan-passport-".concat(passenger.paxType, "-").concat(index)
      },
      on: {
        "apply": function apply(value) {
          return passenger.PaxDocuments[0].number = value;
        }
      }
    }), _c('BButton', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.focus.top",
        value: "T\u1EA3i \u1EA2nh \u0111\u1EC3 qu\xE9t QR Code CCCD/Passport",
        expression: "`Tải Ảnh để quét QR Code CCCD/Passport`",
        modifiers: {
          "hover": true,
          "focus": true,
          "top": true
        }
      }],
      staticClass: "rounded-circle p-50",
      attrs: {
        "variant": "flat-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onClickQrScanHandle("qr-code-scan-by-upload-passport-".concat(passenger.paxType, "-").concat(index));
        }
      }
    }, [_c('IAmIcon', {
      attrs: {
        "icon": "cloudUploadOutline",
        "size": "18",
        "color": "black"
      }
    })], 1), _c('IAmQrCodeScanByUpload', {
      attrs: {
        "idProp": "qr-code-scan-by-upload-passport-".concat(passenger.paxType, "-").concat(index)
      },
      on: {
        "apply": function apply(value) {
          return passenger.PaxDocuments[0].number = value;
        }
      }
    })], 1), ['P'].includes(passenger.docTypeSelect) ? _c('b-col', {
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger, 'nationality'),
        "name": _vm.$t('flight.Nationality'),
        "vid": "nationality-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            staticClass: "bg-white",
            attrs: {
              "id": "nationality-".concat(passenger.paxType, "-").concat(index),
              "placeholder": "M\xE3 qu\u1ED1c gia ".concat(_vm.getValidatePassport(passenger, 'nationality').includes('required') ? '(*)' : ''),
              "contextErrors": validationContext.errors[0]
            },
            model: {
              value: passenger.PaxDocuments[0].nationality,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "nationality", $$v);
              },
              expression: "passenger.PaxDocuments[0].nationality"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e(), ['P'].includes(passenger.docTypeSelect) ? _c('b-col', {
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger, 'isCountry'),
        "name": "Nơi cấp",
        "vid": "passport-place-issued-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            staticClass: "bg-white",
            attrs: {
              "id": "passport-place-issued-".concat(passenger.paxType, "-").concat(index),
              "placeholder": "N\u01A1i c\u1EA5p ".concat(_vm.getValidatePassport(passenger, 'isCountry').includes('required') ? '(*)' : ''),
              "contextErrors": validationContext.errors[0]
            },
            model: {
              value: passenger.PaxDocuments[0].isCountry,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "isCountry", $$v);
              },
              expression: "passenger.PaxDocuments[0].isCountry"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e(), ['P'].includes(passenger.docTypeSelect) ? _c('b-col', {
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3",
        "xl": "2"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger, 'endDate'),
        "name": _vm.$t('flight.expirationDate'),
        "vid": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-exDate-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          }), _c('div', {
            staticClass: "form-control p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control px-50 py-0",
            attrs: {
              "id": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index),
              "name": _vm.$t('flight.expirationDate'),
              "config": Object.assign({}, _vm.configFlatPickrPassport, {
                altInputClass: 'form-control input px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.expirationDate'), " ").concat(_vm.getValidatePassport(passenger, 'endDate').includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-exDate-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1) : _vm._e(), ['P'].includes(passenger.docTypeSelect) ? _c('b-col', {
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger, 'gender'),
        "name": _vm.$t('flight.gender'),
        "vid": "passport-gender-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-gender-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('v-select', {
            staticClass: "w-100 bg-white p-0",
            class: validationContext.errors[0] ? 'border-danger-select' : '',
            attrs: {
              "reduce": function reduce(val) {
                return val.value;
              },
              "label": "label",
              "options": _vm.genderOptions.filter(function (item) {
                return item.value !== 'OTHER';
              }),
              "placeholder": "".concat(_vm.$t('flight.gender'), " ").concat(_vm.getValidatePassport(passenger, 'gender').includes('required') ? '(*)' : '')
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "no-options",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
              },
              proxy: true
            }], null, true),
            model: {
              value: passenger.PaxDocuments[0].gender,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "gender", $$v);
              },
              expression: "passenger.PaxDocuments[0].gender"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-gender-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1) : _vm._e()], 1) : _vm._e(), ['P'].includes(passenger.docTypeSelect) && _vm.flightMaxDate && _vm.isLastFlightDateAfterPassportExpiry(passenger.PaxDocuments[0].endDate, _vm.flightMaxDate) ? _c('div', {
      staticClass: "text-danger mx-1  my-50  "
    }, [_c('small', [_vm._v("(" + _vm._s(_vm.$t('reservation.isLastFlightDateAfterPassportExpiry')) + ")")])]) : ['P'].includes(passenger.docTypeSelect) && _vm.flightMaxDate && _vm.checkPassportLessThan6MonthsBeforeFlightDate(passenger.PaxDocuments[0].endDate, _vm.flightMaxDate) ? _c('div', {
      staticClass: "text-danger mx-1 my-50"
    }, [_c('small', [_vm._v("(" + _vm._s(_vm.$t('reservation.passportLessThan6MonthsBeforeFlight')) + ")")])]) : _vm._e(), _vm.isEnableMemberShipCardAndPassport && !['INFANT'].includes(passenger.paxType) ? _c('div', {
      staticClass: "mt-50 px-25 px-md-1"
    }, [_vm._v(" Thẻ thành viên, SkyJoy, Lotusmiles: "), _c('br'), _vm._l(passenger.memberFly, function (memberCard, memberCardIndex) {
      return _c('div', {
        key: memberCardIndex,
        staticClass: "d-flex mb-50"
      }, [_c('b-input-group', {
        staticClass: "border rounded",
        staticStyle: {
          "max-width": "576px"
        },
        attrs: {
          "id": "member-Fly-".concat(passenger.paxType, "-").concat(index, "-").concat(memberCardIndex)
        }
      }, [_c('b-input-group-prepend', {
        class: _vm.isMobileView ? 'flex-wrap' : '',
        attrs: {
          "id": "member-Fly-".concat(passenger.paxType, "-").concat(index)
        }
      }, [_c('v-select', {
        staticClass: "membership-airline-select rounded mr-25",
        class: _vm.isMobileView ? 'border-secondary' : '',
        staticStyle: {
          "width": "200px",
          "max-height": "38px"
        },
        attrs: {
          "id": "member-Fly-".concat(passenger.paxType, "-").concat(index),
          "options": _vm.getAirlineOptionsGroupByTrips(_vm.selectedTrip, memberCard.airline, index, memberCardIndex),
          "label": "text",
          "clearable": false,
          "placeholder": "".concat(_vm.$t('flight.airline')),
          "reduce": function reduce(val) {
            return val.value;
          }
        },
        scopedSlots: _vm._u([{
          key: "option",
          fn: function fn(data) {
            var _data$text;
            return [_c('span', {
              class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3', " my-25 font-weight-bold")
            }, [_vm._v(" " + _vm._s((_data$text = data.text) !== null && _data$text !== void 0 ? _data$text : data.value) + " ")])];
          }
        }, {
          key: "selected-option",
          fn: function fn(data) {
            var _data$text2;
            return [_c('span', {
              class: "font-weight-bolder text-nowrap text-truncate ".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3'),
              staticStyle: {
                "max-width": "200px"
              }
            }, [_vm._v(" " + _vm._s((_data$text2 = data.text) !== null && _data$text2 !== void 0 ? _data$text2 : data.value) + " ")])];
          }
        }], null, true),
        model: {
          value: memberCard.airline,
          callback: function callback($$v) {
            _vm.$set(memberCard, "airline", $$v);
          },
          expression: "memberCard.airline"
        }
      })], 1), _c('validation-provider', {
        staticClass: "flex-1",
        attrs: {
          "name": ['VJ'].includes(memberCard.airline) ? 'ttvVJ' : _vm.$t('flight.memberFly'),
          "rules": ['VJ'].includes(memberCard.airline) ? 'ttvVJ' : 'numeric',
          "vid": "cardNumber-".concat(passenger.paxType, "-").concat(index, "-").concat(memberCardIndex)
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(validationContext) {
            return [_c('b-form-input', {
              staticStyle: {
                "min-width": "145px"
              },
              attrs: {
                "id": "cardNumber-".concat(passenger.paxType, "-").concat(index, "-").concat(memberCardIndex),
                "placeholder": "".concat(_vm.$t('flight.memberFly')),
                "lazy-formatter": "",
                "formatter": _vm.trimAllInputAndUpper,
                "state": _vm.getValidationState(validationContext) === false ? false : null
              },
              model: {
                value: memberCard.cardNumber,
                callback: function callback($$v) {
                  _vm.$set(memberCard, "cardNumber", $$v);
                },
                expression: "memberCard.cardNumber"
              }
            }), validationContext.errors[0] ? _c('b-tooltip', {
              attrs: {
                "target": "cardNumber-".concat(passenger.paxType, "-").concat(index, "-").concat(memberCardIndex),
                "triggers": "hover focus",
                "placement": "top",
                "boundary": "viewport",
                "variant": "danger",
                "title": validationContext.errors[0]
              }
            }, [_c('span', {
              staticClass: "text-white py-25 mb-0"
            }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
          }
        }], null, true)
      })], 1), _c('b-button', {
        staticClass: "btn-icon ml-25 rounded-pill px-75",
        attrs: {
          "id": "member-Fly-btn-del-".concat(passenger.paxType, "-").concat(index, "-").concat(memberCardIndex),
          "variant": "flat-danger",
          "disabled": passenger.memberFly.length < 2,
          "size": "sm",
          "pill": ""
        },
        on: {
          "click": function click($event) {
            return passenger.memberFly.splice(memberCardIndex, 1);
          }
        }
      }, [_c('feather-icon', {
        attrs: {
          "icon": "MinusIcon"
        }
      })], 1)], 1);
    }), _c('span', {
      staticClass: "px-50 py-25 hover-underline text-info btn btn-flat-info my-25 mr-50 rounded",
      on: {
        "click": function click($event) {
          passenger.memberFly.length < _vm.airlineLists.length && passenger.memberFly.push({
            airline: _vm.selectedTrip[0].airline,
            cardNumber: null
          });
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    }), _vm._v(" Thêm thẻ ")], 1), ['ADULT'].includes(passenger.paxType) && _vm.selectedTrip.some(function (trip) {
      return ['VN1A', 'QH'].includes(trip.source);
    }) && !_vm.isHideFunction ? _c('span', {
      staticClass: "px-50 py-25 hover-underline text-success btn btn-flat-success my-25 rounded",
      on: {
        "click": function click($event) {
          return _vm.handleOpenModalRegisterMembershipCard({
            index: index,
            passenger: passenger
          }, $event);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.registerMembershipCard')) + " ")]) : _vm._e()], 2) : _vm._e()], 1)])], 1);
  }), _c('ModalRegisterMembershipCard', {
    attrs: {
      "member-fly-data": _vm.memberFlyData,
      "data-search-flight": _vm.dataSearchFlight,
      "pax-data": _vm.paxDataSelect,
      "airline-lists": _vm.airlineLists,
      "place": 'create_booking'
    },
    on: {
      "update:memberFlyData": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      },
      "update:member-fly-data": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      }
    }
  }), _c('ModalQuickInputMultiPax', {
    attrs: {
      "max-adults": _vm.getMaxLengthPax('adult'),
      "max-children": _vm.getMaxLengthPax('child'),
      "max-infants": _vm.getMaxLengthPax('infant'),
      "is-create-group-booking": _vm.isCreateGroupBooking
    },
    on: {
      "addMultiplePassengers": _vm.addMultiplePassengers
    }
  }), true ? _c('SuggestShopVJModal', {
    attrs: {
      "passenger-data-to-add": _vm.passengerDataToAdd,
      "airline-code": _vm.selectedTrip[0].airline,
      "segment-list": _vm.segmentList
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.passengerDataToAdd = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.passengerDataToAdd = $event;
      },
      "update:airlineCode": function updateAirlineCode($event) {
        return _vm.$set(_vm.selectedTrip[0], "airline", $event);
      },
      "update:airline-code": function updateAirlineCode($event) {
        return _vm.$set(_vm.selectedTrip[0], "airline", $event);
      },
      "set-visible": _vm.onSetVisible
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }